import React, { Component } from "react";
import HeaderLinks from "../Header/HeaderLinks.jsx";
import { connect } from 'react-redux';
import { FiChevronDown } from 'react-icons/fi'
// import {basePathApi} from 'helper/basePathApi';
import { CLMUserActions } from '../../../redux/actions/CLM-user-action';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import Ads from "../../../assets/img/adswhite.svg"
import Splash from "../../../assets/img/splash.svg"
import MyCarets from "../../../assets/img/myCarets.svg"
import License from "../../../assets/img/clm.svg"
import Campaign from "../../../assets/img/campaign.svg"
import Videos from "../../../assets/img/videos.svg"
import Audios from "../../../assets/img/audios.svg"
import AudiosCat from "../../../assets/img/audio_categories.svg"
import Dashboardblue from "../../../assets/img/dashboardblue.svg"
import Dashboardwhite from "../../../assets/img/dashboardwhite.svg"
import MyOrders from "../../../assets/img/myOrders.svg"
import Earn from "../../../assets/img/earnwhite.svg"
import Tutorial from "../../../assets/img/tutorial.svg"
import MyLogos from "../../../assets/img/myLogo2.svg"

// var module_exists = require('module-exists');

class SidebarPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      dashboardName: 'GTV',
      iconHeight: '20px',
      iconWidth: '20px',
    };

    this.OpenDropDown = this.OpenDropDown.bind(this);
    this.activeRoute = this.activeRoute.bind(this);
    this.createUl = this.createUl.bind(this);
    this.createLi = this.createLi.bind(this);
  }

  createLi(menu_id, menu, key) {
    
    if (typeof menu['parent_labels'][menu_id] === 'undefined') {
      return (
        <li id={"id-" + menu_id} className={this.activeRoute(menu['permissions'][menu_id]['path'], menu_id)} key={key}>
          <span className="nav-link" >
            {
              menu['permissions'][menu_id]['icon'] ?
                <img alt="logo_image" src={require('assets/img/' + menu['permissions'][menu_id]['icon'])} className="imgIconMarRight" />
                :
                <img alt="logo_image" src={require('assets/img/question.svg')} className="imgIconMarRight" />
            }
            <p>
              <Link to={menu['permissions'][menu_id]['path']}> {menu['permissions'][menu_id]['label']}
              </Link>
            </p>
          </span>
        </li>
      );
    }
    else if (typeof menu['parent_labels'][menu_id] !== 'undefined') {
      return (
        <li id={"id-" + menu_id} className="hasChild" key={key}>
          <span className="nav-link" >
            {
              menu['permissions'][menu_id]['icon'] ?
                <img alt="logo_image" src={require('assets/img/' + menu['permissions'][menu_id]['icon'])} className="imgIconMarRight" />
                :
                <img alt="logo_image" src={require('assets/img/question.svg')} className="imgIconMarRight" />
            }
            <p>
              {menu['permissions'][menu_id]['label']}
              <button onClick={() => this.OpenDropDown("id-" + menu_id)} ><FiChevronDown /></button>
            </p>
          </span>
          {this.createUl(menu_id, menu, "subnav")}
        </li>
      );
    }
  }
  createUl(parent, menu, ulClass) {
    if (typeof menu['parent_labels'][parent] !== 'undefined') {
      return (
        <ul className={ulClass}>
          {
            menu['parent_labels'][parent].map((menu_id, key) => {
              return (
                this.createLi(menu_id, menu, key)
              );
            })
          }
        </ul>
      );
    }
  }
  OpenDropDown(id) {
    var sub_height;
    if ($('#' + id).hasClass('openSubMenu')) {
      $('#' + id).removeClass('openSubMenu');
      sub_height = $(".sidebar").outerWidth();
      $(".main-panel").css({ 'width': 'calc(100% - ' + sub_height + 'px)' });
    } else {
      $('#' + id).addClass('openSubMenu');
      sub_height = $(".sidebar").outerWidth();
      $(".main-panel").css({ 'width': 'calc(100% - ' + sub_height + 'px)' });
    }
  }
  activeRoute(routeName, menu_id) {
    var dashboardMenuTemp = this.props.menu;
    if (typeof dashboardMenuTemp['parent_labels'][menu_id] !== 'undefined') {
      return this.props.location.pathname.indexOf(routeName) > -1 ? "active noChild" : "";
    }
    else if (typeof dashboardMenuTemp['parent_labels'][menu_id] === 'undefined') {
      if (dashboardMenuTemp['permissions'][menu_id]['parent_label'] === 0) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? "active lastChild " : "";
      } else {
        return this.props.location.pathname.indexOf(routeName) > -1 ? "active hasParent " : "";
      }

    }
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    var checkLogin = localStorage.getItem('user');
    if (checkLogin) {
      this.props.getMenuCall();
      this.OnLoadIfOpenSubMenu();
    }

  }
  componentDidUpdate() {
    this.OnLoadIfOpenSubMenu();
  }
  OnLoadIfOpenSubMenu() {
    $('li.active').parents('li').addClass('openSubMenu');
  }
  render() {
    const { menu } = this.props;
    return (
      <div id="sidebar" className="sidebar showSideNav" data-color="#2c3356" onMouseEnter={this.props.showSideNav} onMouseLeave={this.props.hideSideNav}>
        <div className="sidebar-background" />
        <div className="sidebar-wrapper">
          {this.state.width <= 991 ? <HeaderLinks /> : null}
          {typeof menu !== 'undefined' && Object.keys(menu['parent_labels']).length > 0 &&
            this.createUl(0, menu, "nav")
          }

          <ul className="nav">
            <li>
              <span className="nav-link flexELem alignCenter gap10p">
                <img alt="logo_image" src={Dashboardwhite} className="imgIconMarRight mt-0F" width={26} />
                <Link to="/CLM/dashboard">Dashboard</Link>
              </span>
            </li>
            <li>
              <span className="nav-link flexELem alignCenter gap10p">
                <img alt="logo_image" src={Tutorial} className="imgIconMarRight mt-0F" width={26} />
                <Link to="/CLM/tutorial">Tutorial</Link>
              </span>
            </li>
            <li>
              <span className="nav-link flexELem alignCenter gap10p">
                <img alt="logo_image" src={MyOrders} className="imgIconMarRight mt-0F" width={26} />
                <Link to="/CLM/orders">My Orders</Link>
              </span>
            </li>
            <li>
              <span className="nav-link flexELem alignCenter gap10p">
                <img alt="logo_image" src={MyCarets} className="imgIconMarRight mt-0F" width={16} />
                <Link to="/CLM/myCaretsVideos">My ^Carets Videos</Link>
              </span>
            </li>

            <li>
              <span className="nav-link flexELem alignCenter gap10p">
                <img alt="logo_image" src={Videos} className="imgIconMarRight mt-0F" width={26} />
                <Link to="/CLM/videos">Videos</Link>
              </span>
            </li>
            {/* <li>
              <span className="nav-link flexELem alignCenter gap10p">
                <img alt="logo_image" src={AudiosCat} className="imgIconMarRight mt-0F" width={26} />
                <Link to="/CLM/clm-audio-cats">Audio Categories</Link>
              </span>
            </li> */}
            <li>
              <span className="nav-link flexELem alignCenter gap10p">
                <img alt="logo_image" src={Audios} className="imgIconMarRight mt-0F" width={26} />
                <Link to="/CLM/clm-audios">Audios</Link>
              </span>
            </li>
            <li>
              <span className="nav-link flexELem alignCenter gap10p">
                <img alt="logo_image" src={Ads} className="imgIconMarRight mt-0F" width={16} />
                <Link to="/CLM/ads">Promotions</Link>
              </span>
            </li>
            <li>
              <span className="nav-link flexELem alignCenter gap10p">
                <img alt="logo_image" src={Splash} className="imgIconMarRight mt-0F" width={16} />
                <Link to="/CLM/splash">Splash</Link>
              </span>
            </li> 
            <li>
              <span className="nav-link flexELem alignCenter gap10p">
                <img alt="logo_image" src={MyLogos} className="imgIconMarRight mt-0F" width={16} />
                <Link to="/CLM/logos">My Logos</Link>
              </span>
            </li> 
            <li>
              <span className="nav-link flexELem alignCenter gap10p">
                <img alt="logo_image" src={License} className="imgIconMarRight mt-0F" width={16} />
                <Link to="/CLM/License">My ^Caret Licenses</Link>
              </span>
            </li>  
            {/* <li>
              <span className="nav-link flexELem alignCenter gap10p">
                <img alt="logo_image" src={LsReport} className="imgIconMarRight mt-0F" width={16} />
                <Link to="/CLM/corporate">^Caret Licenses Request</Link>
              </span>
            </li> */}
            <li>
              <span className="nav-link flexELem alignCenter gap10p">
                <img alt="logo_image" src={Earn} className="imgIconMarRight mt-0F" width={16} />
                <Link to="/CLM/payments">Payment History</Link>
              </span>
            </li>
            {/* <li>
              <span className="nav-link flexELem alignCenter gap10p">
                <img alt="logo_image" src={Reporting} className="imgIconMarRight mt-0F" width={26} />
                <Link to="/Advertiser/ads">Reporting</Link>
              </span>
            </li> */}
          </ul>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return ({
    getMenuCall: () => {dispatch(CLMUserActions.dashboardMenu()) },
  });
};

function mapStateToProps(state) {
  const { userAdmin, menu, loading } = state.CLMReducer;
  return {
    userAdmin, menu, loading
  };
};

const Sidebar = connect(mapStateToProps, mapDispatchToProps)(SidebarPage);
export default Sidebar;

