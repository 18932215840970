import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import Select from 'react-select';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import { RiVideoLine } from 'react-icons/ri';
import AdForm from "./AdForm";
// import CampaignForm from "./CampaignForm";
import { hasPermission } from 'helper/hasPermission';
import Confirmalertfordelete from '../../ThemeComponents/confirmAlertForDelete';
import { adminLabels } from '../../../redux/constant/admin-label-constant';
import OpenModalButton from '../../ThemeComponents/openModelButton';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
import Pagination from '../../ThemeComponents/Pagination';
import DatePicker from "react-datepicker";
import { adService } from '../../../services/ad';
import { licenseService } from '../../../services/license';
import ReactValidator from "simple-react-validator";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "react-switch";

import Eye from "../../../assets/img/eye.svg"
import { filter } from 'lodash';


var cloneDeep = require('lodash.clonedeep');
var Modal = require('react-bootstrap-modal');
const moment = window.moment;

class AdListingPage extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props.match.params;
    const user = JSON.parse(localStorage.getItem('user'));
    this.state = {
      addModalForm: false,
      editModalForm: false,
      openVideoModal: false,
      campaignModal: false,
      campaignData: OC.CAMPAIGN,
      video_url: '',
      selectedVideo: [],
      sorted: '',
      filtered: '',
      filter:'',
      license: [],
      search: { a: 1 },
      pageSize: 10,
      userID: user.data ? user.data.id : null,
    };

    this.editRecord = this.editRecord.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
    this.deleteConfirmModal = this.deleteConfirmModal.bind(this);
    this.getPaginationData = this.getPaginationData.bind(this);
    this.filterSearchHandle = this.filterSearchHandle.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.getUsersCall = this.getUsersCall.bind(this);
    this.videoModal = this.videoModal.bind(this);
    this.closeModal = HF.closeModal.bind(this);
    this.callFunction = this.callFunction.bind(this);
    this.getLicenseCall = this.getLicenseCall.bind(this);
  };

  callFunction(formData, formType) {
    this.setState({ campaignData: false });
  }

  videoModal(url) {
    this.setState({ video_url: url }, () => {
      this.setState({ openVideoModal: true });
      console.log('url', this.state.video_url);
    });
  }
  submitForm(formData, formType) {
    if (formType === 'add') {
      adService.storeAd(formData).then(res => {
        this.setState({ addModalForm: false });
        this.pagination.dataCall();
      });
    } else if (formType === 'edit') {
      adService.editAd(formData, this.state.editRec.id).then(res => {
        this.setState({ editModalForm: false });
        this.pagination.dataCall();
      });
    }
  }

  filterSearchHandle(e) {
    this.setState({
      'filtered': e.target.value
    });
  }

  editRecord(id) {
    adService.getAd(id).then(res => {
      console.log(res);
      this.setState({ editRec: res.data }, () => {
        this.setState({ editModalForm: true });
      });
    });
  }

  showCampaignModal = (id, user_id) => {

    let cmpdata = { ...this.state.campaignData };
    cmpdata['user_id'] = user_id;
    cmpdata['ad_id'] = id;
    this.setState({ campaignData: cmpdata });
    this.setState({ campaignModal: true });
  }


  deleteRecord(recID) {
    adService.deleteAd(recID).then(res => {
      this.pagination.dataCall();
    });
  }

  deleteConfirmModal(recID) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Record" description="Are you sure to delete the Record?" onClosePro={onClose} deleteType={() => this.deleteRecord(recID)} />
        )
      }
    })
  }


  getPaginationData(page, filter, sort, pageSize, status) {
    this.setState({ pageSize: pageSize }, function () {
      adService.paginationData(this.state.userID, page, filter, sort, pageSize, status, this.state.search);
    });
  }

  updateStatus = recID => (e) => {
    // return false;
    adService.updateAdStatus(recID, { is_active: e.target.checked ? 1 : 0 }).then(
      response => {
        adService.paginationData(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
        this.pagination.dataCall();
      }
    );
  }

  updateBlocked = recID => (e) => {
    // return false;
    adService.blockRecord(recID).then(
      response => {
        adService.paginationData(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
        this.pagination.dataCall();
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.rec_status !== prevState.rec_status) {
      this.table.state.onFetchData(this.table.state, this.table.instance)
    }

  }

  paginationCall = (data) => {
    return adService.paginationData(this.state.userID, data.page, data.filter || this.state.filter, data.sort, data.pageSize, this.state.rec_status, this.state.search);
  }


  getUsersCall() {
    adService.getUsers().then(res => {
      console.log('Users', res);
      this.setState({ users: res.data });
    });
  }

  componentDidMount() {
    this.getUsersCall();
    this.getLicenseCall();
  }

  getLicenseCall(){
  licenseService.getCaretLicense().then(res => {
          console.log('license',res.data);
          this.setState({license:res.data});
        });
    }

  handleTypeChange = (selectedOption) => {
  // console.log("selectedOption ==========> ", selectedOption);
  this.setState({
    filter: selectedOption ? selectedOption.value : ''
    }, () => {
        // Ensure this runs only after the state update is complete
        this.pagination.dataCall();
    });
  };



  render() {
    const { license, filtered, editRec, users, campaignData } = this.state;
    const columns = [
      {
        Header: "Ads Listing",
        headerClassName: 'headerPagination',
        columns: [
          {
            Header: "Video",
            accessor: "video_url",
            Cell: ({ row, original }) => (
              original.image_url ? (
                <div style={{ cursor: 'pointer', fontSize: '20px' }} onClick={() => this.videoModal(original.video_url)}>
                  <img style={{ hight: "25px", width: "40px" }} src={original.image_url} />
                </div>
              ) : (
                <div style={{ cursor: 'pointer', fontSize: '20px' }} onClick={() => this.videoModal(original.video_url)}>
                  <RiVideoLine size={40} />
                </div>
              )
            ),
            className: "",
            sortable: false
          },
          {
            Header: "Title",
            accessor: "ad_title",
            className: "",
            sortable: false
          },
          {
            Header: "License",
            accessor: "license",
            Cell: ({ original }) => {
              // console.log("original license", original.license);
              return (
                <div>
                  {original.license && original.license.length > 0
                    && original.license.map((item, index) => (
                        <div key={index}>{item.caret_title}</div>
                      ))
                    }
                </div>
              );
            },
            className: "",
            sortable: false
          },
          {
            Header: "Description",
            accessor: "ad_description",
            className: "justifyStart text-left text-wrap",
            headerClassName: "justifyStart",
            minWidth: 300,
            sortable: false
          },
          {
            Header: "Watch",
            accessor: "watch_count",
            className: "",
            sortable: false
          },



          {
            Header: "Status",
            accessor: "is_active",
            Cell: ({ row }) => (
              // <label className="container-check">
              <label className={`${row.is_active ? "invoiceStatusActive" : "invoiceStatus"}`}>
                {row.is_active ? "Active" : "In-Active"}
              </label>
            ),
            className: "",
            sortable: false
          },

          {
            Header: "Actions",
            accessor: "id",
            Cell: ({ row, original }) => (
              <div>



                {/* <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Add To Campaign</Popover>}>
                        <span>
                        {
                            <button type='button' className="orderDetailsAction" onClick={() => this.showCampaignModal(row.id,original.user_id)}> C </button>
                        }

                    </span>
                </OverlayTrigger> */}

                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Edit Ad</Popover>}>
                  <span>
                    {
                      <button type='button' className="editIcon orderDetailsAction" onClick={() => this.editRecord(row.id)}>&nbsp;</button>
                    }

                  </span>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Delete Ad</Popover>}>
                  <span>
                    {
                      <button type='button' className="deleteIcon orderDetailsAction" onClick={() => this.deleteConfirmModal(row.id)}>&nbsp;</button>
                    }
                  </span>

                </OverlayTrigger>
              </div>
            ),
            className: "justifyEnd text-right",
            headerClassName: "justifyEnd",
            sortable: false
          }


        ]
      }
    ];

    return (
      <div className="content pl-2-5F">
        <Row>
          <Col md={12}>
            <div className="secHeading">My Promotions</div>
            <OpenModalButton
              openModal={HF.openModal.bind(this, "addModalForm")}
              classButton={['btn btnDark text-white w-autoF']}
              buttonName="Add New"
              classIcon={['fa', 'fa-plus']}
            />
          </Col>
          
          <Col md={4}>
            <div className="form-group fixBotNegative">
              <label htmlFor="plan"></label>
              <Select
                name="typeSelect"
                options={[
                  { value: "", label: "All Licenses" }, // Add "ALL" option at the top
                  ...(this.state.license || []), // Spread the rest of the licenses
                ]}
                className="basic-single"
                classNamePrefix="select"
                placeholder="Select License"
                value={
                  this.state.filter
                    ? [{ value: "", label: "All Licenses" }, ...(this.state.license || [])].find(
                        ls => ls.value === this.state.filter
                      )
                    : { value: "", label: "All Licenses" }
                }
                onChange={this.handleTypeChange}
              />
            </div>
          </Col>

          <Col md={12}>
            <Pagination
              ref={(p) => this.pagination = p}
              showPagination={false}
              columns={columns}
              pageSize={20}
              getDataCall={this.paginationCall}
              filterView={true}
              filterPlaceHolder={'Ads'}
              defaultSorted={
                [
                  {
                    id: 'id',
                    desc: true
                  }
                ]
              }
              // downloadData={true}
              // downloadFileName={'Orders'}
              // lowerContent = {null}
              // filterPlaceHolder = {'Orders'}
              noDataText='No Record found'
              getRowProps={this.getRowProps}
            // showAllToggle={true}
            />
          </Col>
        </Row>
        {users !== null &&
          <Modal backdrop={'static'} show={this.state.addModalForm} onHide={HF.closeModal.bind(this, "addModalForm")} aria-labelledby="ModalHeader" className="modalLG modal-lg mx-auto">
            <Modal.Header closeButton>
              <Modal.Title id='ModalHeader' className="headerTitle">Add Promotion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <Card bsClass={["innerCard mb-none"]} content={
                    <AdForm
                      closeModel={HF.closeModal.bind(this, "addModalForm")}
                      formTypePro="add"
                      recPro={OC.ADV}
                      user_id={this.state.userID}
                      submitForm={this.submitForm}
                      componentPro="AdListing"
                      users={users}
                    />
                  } />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        }
        {/*Add Record Modal End*/}

        {/*Edit Record Modal start*/}
        {editRec &&
          <Modal backdrop={'static'} show={this.state.editModalForm} onHide={HF.closeModal.bind(this, "editModalForm")} aria-labelledby="ModalHeader" className="modalLG modal-lg mx-auto">
            <Modal.Header closeButton>
              <Modal.Title id='ModalHeader' className="headerTitle">Edit Promotion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <Card bsClass={["innerCard mb-none"]} content={
                    <AdForm
                      closeModel={HF.closeModal.bind(this, "editModalForm")}
                      formTypePro="edit"
                      recPro={editRec}
                      user_id={this.state.userID}
                      submitForm={this.submitForm}
                      users={users}
                    />
                  } />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        }

        {
          <Modal backdrop={'static'} show={this.state.openVideoModal} onHide={HF.closeModal.bind(this, "openVideoModal")} aria-labelledby="ModalHeader" className="modalLG modal-lg mx-auto">
            <Modal.Header closeButton>
              <Modal.Title id='ModalHeader' className="headerTitle">Promotion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12" style={{ textAlignVertical: "center", textAlign: "center" }}>
                  <video key={this.state.video_url} id="playVid" controls className='videoPreview'><source src={this.state.video_url} type="video/mp4" /></video>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        }
      </div>
    );
  };
}
const AdListing = AdListingPage;
export default AdListing;
