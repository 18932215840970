import { advertiserConstants } from 'redux/constant/advertiser-constant';
import { CLMConstants } from "redux/constant/CLM-constant";
import { alertActions } from "./alert-actions";
import { history } from "helper/history";
import { advertiserUserService } from 'services/advertiserUser';
import { CLMUserService } from "services/CLMUser";
import { helperFunctions as HF } from "../../helper/helperFunctions";
import Swal from "sweetalert2";
import { data } from "jquery";
//import { campaignService } from 'services/campaign';

export const CLMUserActions = {
    login,
    logout,
    dashboard,
    dashboardMenu,
    cartLogin
};

function dashboardMenu() {
    return dispatch => {
        dispatch(request());
        CLMUserService.dashboardMenu().then(
            response => {
                dispatch(success(response));
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };

    function request() {
        return { type: CLMConstants.GET_MENU_DASHBOARD_REQUEST };
    }
    function success(response) {
        return {
            type: CLMConstants.GET_MENU_DASHBOARD_SUCCESS,
            payload: response
        };
    }
    function failure(error) {
        return {
            type: CLMConstants.GET_MENU_DASHBOARD_FAILURE,
            payload: error
        };
    }
}

function login(email, password, role = null, keyword = null) {
    return dispatch => {
        dispatch(request({ email }));
        const userService = HF.hasRole('advertiser') ? advertiserUserService: CLMUserService;
        userService.login(email, password, role, keyword)
            .then(
                user => {
                    dispatch(success(user));
                    if(HF.hasRole('advertiser') == false && HF.hasRole('clm') == false){
                        localStorage.removeItem('user');
                        history.push('/');

                    }else if(HF.hasRole('clm') == true && HF.hasRole('advertiser') == false){
                        
                        if(user.data.profile.industry_id && user.data.profile.phone_number && user.data.profile.business_name)
                        {
                            history.push('/CLM/dashboard')
                        }else{
                            history.push('/CLM/EditProfile')
                        }
                        
                        // if(keyword){
                        //     history.push('/CLM/search?keyword=' + keyword);
                        // }else{
                        //     // history.push('/CLM/dashboard')
                        //     if(user.data.profile.industry_id && user.data.profile.phone_number && user.data.profile.business_name)
                        //     {
                        //         history.push('/CLM/dashboard')
                        //     }else{
                        //         history.push('/CLM/EditProfile')
                        //     }
                        // }
                    }else if (HF.hasRole('advertiser') && HF.hasRole('clm')){

                        if(user.data.profile.industry_id && user.data.profile.phone_number && user.data.profile.business_name)
                            {
                                history.push('/CLM/dashboard')
                            }else{
                                history.push('/CLM/EditProfile')
                            }
                        // Swal.fire({
                        //     title: 'Select Role',
                        //     text: 'Please select either CLM or Advertiser to proceed.',
                        //     icon: 'question',
                        //     showCancelButton: true,
                        //     confirmButtonText: 'advertiser',
                        //     cancelButtonText: 'clm',
                        //     reverseButtons: true,
                        //     customClass: {
                        //         confirmButton: 'swal-confirm-btn',
                        //         cancelButton: 'swal-cancel-btn'
                        //     }
                        // }).then((result) => {
                        //     console.log("result ===>  ",result);
                        //     if (result.isConfirmed) {
                        //         // User selected Advertiser
                        //         if (user.data.profile.industry_id && user.data.profile.phone_number && user.data.profile.business_name) {
                        //             history.push('/Advertiser/dashboard');
                        //         } else {
                        //             history.push('/Advertiser/EditProfile');
                        //         }
                        //     } else if (result.dismiss === Swal.DismissReason.cancel) {
                        //         // User selected CLM
                        //         if(keyword){
                        //             history.push('/CLM/search?keyword=' + keyword);
                        //         }else{
                        //             // history.push('/CLM/dashboard')
                        //             if(user.data.profile.industry_id && user.data.profile.phone_number && user.data.profile.business_name)
                        //                 {
                        //                     history.push('/CLM/dashboard')
                        //                 }else{
                        //                     history.push('/CLM/EditProfile')
                        //                 }
                        //         }
                        //     }
                        // });
                        
                    }else if(HF.hasRole('advertiser') == true && HF.hasRole('clm') == false){

                        if(user.data.profile.industry_id && user.data.profile.phone_number && user.data.profile.business_name)
                        {
                            history.push('/Advertiser/dashboard')
                        }else{
                            history.push('/Advertiser/EditProfile')
                        }
                    }
        // CLMUserService.login(email, password, role).then(
        //     user => {
        //         console.log("ROLE ****", user.data.roles);
        //         dispatch(success(user));
        //         if (HF.hasRole("clm") == false) {
        //             localStorage.removeItem("user");
        //             history.push("/CLM/CLM-login");
        //         } else {
        //             history.push("/CLM/dashboard");
        //         }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
        );
    };

    // function request(email) {
    //     return { type: CLMConstants.LOGIN_CLM_REQUEST, payload: email };
    // }
    // function success(user) {
    //     return { type: CLMConstants.LOGIN_CLM_SUCCESS, payload: user };
    // }
    // function failure(error) {
    //     return { type: CLMConstants.LOGIN_CLM_FAILURE, payload: error };
    // }
    function request(email) {
        const type = HF.hasRole('advertiser') ? advertiserConstants.LOGIN_ADVERTISER_REQUEST : CLMConstants.LOGIN_CLM_REQUEST;
        return { type, payload: email };
    }

    function success(user) {
        const type = HF.hasRole('advertiser') ? advertiserConstants.LOGIN_ADVERTISER_SUCCESS : CLMConstants.LOGIN_CLM_SUCCESS;
        return { type, payload: user };
    }

    function failure(error) {
        const type = HF.hasRole('advertiser') ? advertiserConstants.LOGIN_ADVERTISER_FAILURE : CLMConstants.LOGIN_CLM_FAILURE;
        return { type, payload: error };
    }
}
function cartLogin(email, password, role = null, keyword = null) {
    return dispatch => {
        dispatch(request({ email }));
        
        CLMUserService.login(email, password, role, keyword).then(
            user => {
                console.log("ROLE ****", user.data.roles);
                dispatch(success(user));
                if (HF.hasRole("clm") == false) {
                    localStorage.removeItem("user");
                    history.push("/CLM-cart-checkou");
                } else {
                    history.push("/CLM-cart-checkout");
                }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
        );
    };

    function request(email) {
        return { type: CLMConstants.LOGIN_CLM_REQUEST, payload: email };
    }
    function success(user) {
        return { type: CLMConstants.LOGIN_CLM_SUCCESS, payload: user };
    }
    function failure(error) {
        return { type: CLMConstants.LOGIN_CLM_FAILURE, payload: error };
    }
    
}

function logout() {
    return dispatch => {
        dispatch(request());
        CLMUserService.logout().then(
            response => {
                dispatch(success(response));
                // console.log("logoutOutFromServer 3");
                localStorage.removeItem("user");
                history.push("/CLM/CLM-login");
            },
            error => {
                // console.log("logoutOutFromServer 4");
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };

    function request() {
        return { type: CLMConstants.LOGOUT_CLM_REQUEST };
    }
    function success(response) {
        return { type: CLMConstants.LOGOUT_CLM_SUCCESS, payload: response };
    }
    function failure(error) {
        return { type: CLMConstants.LOGOUT_CLM_FAILURE, payload: error };
    }
}

function dashboard() {
    return dispatch => {
        console.log("aaaaaaaa");
        dispatch(request());
        console.log("bbbbbbb");

        CLMUserService.dashboard().then(
            response => {
                dispatch(success(response));
                history.push("/CLM/dashboard");
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };

    function request() {
        return { type: CLMConstants.GET_DASHBOARD_REQUEST };
    }
    function success(response) {
        return { type: CLMConstants.GET_DASHBOARD_SUCCESS, payload: response };
    }
    function failure(error) {
        return { type: CLMConstants.GET_DASHBOARD_FAILURE, payload: error };
    }
}
