import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import Hls from 'hls.js';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import {RiVideoLine} from 'react-icons/ri';
import AdForm from "./AdForm";
import CampaignForm from "./CampaignForm";
import { hasPermission } from 'helper/hasPermission';
import Confirmalertfordelete from '../../ThemeComponents/confirmAlertForDelete';
import { adminLabels } from '../../../redux/constant/admin-label-constant';
import OpenModalButton from '../../ThemeComponents/openModelButton';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
import Pagination from '../../ThemeComponents/Pagination';
import DatePicker from "react-datepicker";
import { adService } from '../../../services/ad';
import ReactValidator from "simple-react-validator";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "react-switch";
var cloneDeep = require('lodash.clonedeep');
var Modal = require('react-bootstrap-modal');
const moment = window.moment;

class AdListingPage extends Component{
  constructor(props){
  	super(props);
    const { id } = this.props.match.params;
    this.dateValidator = new ReactValidator();
    var today = new Date();
    today.setDate(today.getDate() + 1);
    var lastmonth = new Date();
    lastmonth.setMonth(lastmonth.getMonth() - 1);
    var startDate = lastmonth.getFullYear() + '-' + (lastmonth.getMonth() + 1) + '-' + lastmonth.getDate();
    var endDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  	this.state = {
  	  addModalForm  : false,
      editModalForm : false,
      openVideoModal: false,
      campaignModal:false,
      campaignData:OC.CAMPAIGN,
      // video_url:'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4',
      // video_url:'https://flipfit-cdn.akamaized.net/flip_hls/662aae7a42cd740019b91dec-3e114f/video_h1.m3u8',
      video_url:'',
      selectedVideo:[],
      sorted   : '',
      filtered : '',
      search:{
        fromDate : startDate,
        toDate: endDate},
      pageSize : 10,
      userID:id,
  	};

  	this.editRecord = this.editRecord.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
  	this.deleteConfirmModal = this.deleteConfirmModal.bind(this);
    this.getPaginationData  = this.getPaginationData.bind(this);
    this.filterSearchHandle     = this.filterSearchHandle.bind(this);
    this.submitForm= this.submitForm.bind(this);
    this.getUsersCall= this.getUsersCall.bind(this);
    this.videoModal = this.videoModal.bind(this);
    this.closeModal = HF.closeModal.bind(this);
    this.callFunction= this.callFunction.bind(this);
    this.videoRef = React.createRef(); 
  };

  callFunction(formData, formType) {
    this.setState({campaignData:false});
  }

  videoModal(url) {

    this.setState({video_url:url}, ()=>{
        this.setState({openVideoModal:true});
        console.log('url',this.state.video_url);
    });

  }

  loadVideo = () => {
    const video = this.videoRef.current;
    const videoUrl = this.state.video_url;
    const fileExtension = videoUrl.split('.').pop().toLowerCase();
    console.log('fileExtension =>', fileExtension);

    console.log('videoUrl', videoUrl);

    // Check if HLS.js is supported
    if(fileExtension === 'm3u8') {
      
   
      if (Hls.isSupported()) {
        console.log('HLS.js is supported');
        const hls = new Hls();

        // Add event listener to detect if loading of video source is successful
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          console.log('Manifest parsed and video source is ready');
        });

        hls.loadSource(videoUrl); // Load the .m3u8 video
        hls.attachMedia(video);

        // Error handling
        hls.on(Hls.Events.ERROR, (event, data) => {
          if (data.fatal) {
            console.error('HLS.js error:', data);
          }
        });

        hls.on(Hls.Events.LEVEL_LOADED, (event, data) => {
          console.log('Level loaded', data);
        });
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        // For Safari (which natively supports HLS)
        video.src = videoUrl;
      } else {
        console.error('Your browser does not support HLS or m3u8');
      }
    }else{
      video.src = videoUrl;
    }
  };



  submitForm(formData, formType) {
    if (formType === 'add'){
        adService.storeAd(formData).then(res => {
            this.setState({addModalForm:false});
            this.pagination.dataCall();
          });
    }else if (formType === 'edit'){
        adService.editAd(formData, this.state.editRec.id).then(res => {
            this.setState({editModalForm:false});
            this.pagination.dataCall();
          });
    }
  }

  filterSearchHandle(e) {
    this.setState({
      'filtered': e.target.value
    });
  }

  editRecord(id)
  {
    adService.getAd(id).then(res => {
        console.log(res.data);
        this.setState({editRec:res.data}, ()=>{
            this.setState({editModalForm:true});
            console.log('imageUrl',res.image);
        });
      });
  }

  showCampaignModal = (id,user_id) => {

    let cmpdata = { ...this.state.campaignData };
    cmpdata['user_id'] = user_id;
    cmpdata['ad_id'] = id;
    this.setState({campaignData: cmpdata});
    this.setState({campaignModal:true});
  }


  deleteRecord(recID)
  {
    adService.deleteAd(recID).then(res => {
      this.pagination.dataCall();
    });
  }

  deleteConfirmModal(recID)
  {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Record" description="Are you sure to delete the Record?" onClosePro={onClose} deleteType={() => this.deleteRecord(recID)} />
        )
      }
    })
  }


getPaginationData(page, filter, sort, pageSize, status)
{
    this.setState({ pageSize: pageSize }, function () {
      adService.paginationData(this.state.userID, page, filter, sort, pageSize, status,this.state.search);
    });
}

updateStatus = recID => (e) => {
// return false;
adService.updateAdStatus(recID, { is_active: e.target.checked ? 1 : 0 }).then(
    response => {
    adService.paginationData(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status,this.state.search);
    this.pagination.dataCall();
    }
);
}

updateBlocked = recID => (e) => {
    // return false;
    adService.blockRecord(recID).then(
        response => {
        adService.paginationData(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status,this.state.search);
        this.pagination.dataCall();
        }
    );
    }

  componentDidUpdate(prevProps, prevState)
  {
    if (this.state.rec_status !== prevState.rec_status) {
      this.table.state.onFetchData(this.table.state, this.table.instance)
    }
    //  this.loadVideo();

  }

  paginationCall = (data) => {
    return adService.paginationData(this.state.userID, data.page, data.filter, data.sort, data.pageSize,this.state.rec_status,this.state.search);
  }
  
  getUsersCall(){
    adService.getUsers().then(res => {
        console.log('Users',res);
        this.setState({users:res.data});
      });
  }

componentDidMount() {
    this.getUsersCall();
  }


DateChangeHandler = (value, field) => {
    const temp = cloneDeep(this.state.search);
    temp[field] = value === null ? '' : moment(value).format("YYYY-MM-DD");

    this.setState({ search : temp  }, () => {

        if (this.dateValidator.allValid()) {
            adService.paginationData(this.state.userID, this.props.current_page,'', '', this.state.pageSize, this.state.rec_status,this.state.search);
            this.pagination.dataCall();
        } else {
            this.dateValidator.showMessages();
            this.forceUpdate();
        }
    })
}
dateElement(label, name, value, rules) {
    var selectedDate =  this.state.search[name] !== '' ? new Date(this.state.search[name]) :'';
    const { fromDate, toDate } = this.state.search;
    console.log(value);
    return (
        <div className='form-group fullCalendar' id={name}>
              <label>{label}</label>
              <DatePicker className="form-control calender_icon"  selected={  selectedDate } name={name} onChange={ (event) => this.DateChangeHandler(event, name) } />
              {
                  ( fromDate !== '' && toDate !== '' ) &&
                  this.dateValidator.message(name, value, rules)
              }
        </div>
    );
  }
  render()
  {
  	const { filtered,editRec,users,campaignData } = this.state;
	const { toDate, fromDate } = this.state.search;
    const columns = [
        {
          Header:"Ads Listing",
          headerClassName:'headerPagination',
          columns: [
            // {
            //     Header: "#",
            //     accessor: "id",
            //     className: "",
            //     sortable: false
            // },
            {
                Header: "Video",
                accessor: "video_url",
                Cell: ({ row, original }) => (
                  original.image_url ? (
                    <div style={{ cursor: 'pointer', fontSize: '20px' }} onClick={() => this.videoModal(original.video_url)}>
                      {/* <img src='{{original.ad.image_url}}' /> */}
                      <img style={{hight:"25px", width:"40px"}} src={original.image_url} />
                    </div>
                  ) : (
                    <div style={{ cursor: 'pointer', fontSize: '20px' }} onClick={() => this.videoModal(original.video_url)}>
                      <RiVideoLine />
                    </div>
                  )
                ),

            className: "",
            sortable: false
            },
            {
                Header: "Details",
                accessor: "ad_description",
                className: "justifyStart text-left text-wrap",
                headerClassName: "justifyStart",
                minWidth: 300,
                sortable: false
            },
            {
                Header: "Watch",
                accessor: "watch_count",
                className: "",
                sortable: false
            },

            {
                Header: "Advertiser",
                accessor: "user",
                Cell: ({value}) => (
                  <div>
                   {value.username}
                  </div>
                  ),
                className: "",
                sortable:false
              },



            {
              Header: "Status",
              accessor: "is_active",
              Cell: ({row}) => (
                  <label className="container-check">
                    &nbsp;
                    <input
                      type="checkbox" name="is_active" className="checkbox" value={row.is_active? 1 : 0} onChange={this.updateStatus(row.id)}  checked={row.is_active?1:0}
                     /><span className="checkmark"></span>
                  </label>
                ),
              className: "",
              sortable:false
            },

            {
                Header: "Blocked",
                accessor: "is_blocked",
                Cell: ({row}) => (
                    <label className="container-check">
                      &nbsp;
                      <input
                        type="checkbox" name="is_blocked" className="checkbox" value={row.is_blocked? 1 : 0} onChange={this.updateBlocked(row.id)}  checked={row.is_blocked?1:0}
                       /><span className="checkmark"></span>
                    </label>
                  ),
                className: "",
                sortable:false
              },

            {
              Header:"Actions",
              accessor: "id",
              Cell: ({row,original}) => (
                <div>



                {/* <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Run Campaign</Popover>}>
                        <span>
                        {
                            <button type='button' className="orderDetailsAction" onClick={() => this.showCampaignModal(row.id,original.user_id)}> C </button>
                        }

                    </span>
                </OverlayTrigger> */}

                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Edit Ad</Popover>}>
                  <span>
                  {
                    <button type='button' className="editIcon orderDetailsAction" onClick={() => this.editRecord(row.id)}>&nbsp;</button>
                  }

                  </span>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Delete Ad</Popover>}>
                  <span>
                  {
                    <button type='button' className="deleteIcon orderDetailsAction" onClick={() => this.deleteConfirmModal(row.id)}>&nbsp;</button>
                  }
                  </span>

                </OverlayTrigger>
                </div>
                ),
              className: "justifyEnd text-right",
              headerClassName: "justifyEnd",
              sortable:false
            }


          ]
        }
    ];

  	return(
  		<div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                	<div>
                  	<Grid fluid>
                      <Row>
                          <Col md={12}>
                            <div className="secHeading">Promotion Listing</div>
                          </Col>



                          <Col md={12}>
                            <div className="flexElem flexResponsive mb-lg" style={{"alignItems": "flex-start", "justifyContent": "space-between"}}>
                              <div className="">
                                <OpenModalButton
                                  openModal={HF.openModal.bind(this, "addModalForm")}
                                  classButton={['backButton pt-sm no_radius pb-sm primary mt-none btn-block btn-info btn']}
                                  buttonName="Add New"
                                  classIcon={['fa', 'fa-plus']}
                                />
                              </div>


                            </div>
                          </Col>

                          <Col md={2}>
                                <div className='row flexElem flexResponsive align-items-center' >
                                    <div className="col-md-12" >
                                    {
                                        this.dateElement('Date From', 'fromDate', fromDate!=='' && moment(fromDate, 'YYYY-MM-DD'), [{ before_or_equal: moment(this.state.search.toDate) }])
                                    }
                                    </div>
                                </div>
                            </Col>
                            <Col md={2} >
                                <div className='row flexElem flexResponsive align-items-center' >
                                    <div className="col-md-12" >
                                    {
                                        this.dateElement('Date To', 'toDate', toDate!=='' && moment(toDate, 'YYYY-MM-DD'), [{ after_or_equal: moment(this.state.search.fromDate) }])
                                    }
                                    </div>

                                </div>
                            </Col>


                          <Col md={12} className="mt-md">
                          <Pagination
                          ref={(p) => this.pagination = p}
                          showPagination={false}
                          columns={columns}
                          pageSize={20}
                          getDataCall={this.paginationCall}
                          filterView={true}
                          filterPlaceHolder={'Ads'}
                          defaultSorted={
                            [
                              {
                                id: 'id',
                                desc: true
                              }
                            ]
                          }
                          // downloadData={true}
                          // downloadFileName={'Orders'}
                          // lowerContent = {null}
                          // filterPlaceHolder = {'Orders'}
                          noDataText='No Record found'
                          getRowProps={this.getRowProps}
                        // showAllToggle={true}
                        />
                          </Col>
                      </Row>
                    </Grid>

                    { users !== null &&
                      <Modal backdrop={'static'} show={this.state.addModalForm} onHide={HF.closeModal.bind(this, "addModalForm")} aria-labelledby="ModalHeader" >
                        <Modal.Header closeButton>
                          <Modal.Title id='ModalHeader' className="headerTitle">Add Promotion</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-md-12">
                              <Card bsClass={["innerCard mb-none"]} content={
                                <AdForm
                                  closeModel={HF.closeModal.bind(this, "addModalForm")}
                                  formTypePro="add"
                                  recPro={OC.ADV}
                                  submitForm= { this.submitForm }
                                  componentPro="AdListing"
                                  users={users}
                                />
                              } />
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    }
                    {/*Add Record Modal End*/}

              		  {/*Edit Record Modal start*/}
                    { editRec &&
                      <Modal backdrop={'static'} show={this.state.editModalForm} onHide={HF.closeModal.bind(this, "editModalForm")} aria-labelledby="ModalHeader" >
                      <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Edit Promotion</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-md-12">
                          <Card bsClass={["innerCard mb-none"]} content={
                            <AdForm
                              closeModel={HF.closeModal.bind(this, "editModalForm")}
                              formTypePro="edit"
                              recPro={editRec}
                              submitForm= { this.submitForm }
                              users={users}
                            />
                          } />
                          </div>
                        </div>
                      </Modal.Body>
                      </Modal>
                    }

{
                      <Modal backdrop={'static'} show={this.state.campaignModal} onHide={HF.closeModal.bind(this, "campaignModal")} aria-labelledby="ModalHeader" >
                        <Modal.Header closeButton>
                          <Modal.Title id='ModalHeader' className="headerTitle">Add Campaign</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-md-12">
                              <Card bsClass={["innerCard mb-none"]} content={
                                <CampaignForm
                                  closeModel={HF.closeModal.bind(this, "campaignModal")}
                                  formTypePro="add"
                                  recPro={campaignData}
                                  callFunction= { this.callFunction }
                                  componentPro="AdListing"
                                />
                              } />
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    }

                    {
                      <Modal backdrop={'static'} show={this.state.openVideoModal} onHide={HF.closeModal.bind(this, "openVideoModal")} aria-labelledby="ModalHeader" >
                      <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Ad</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-md-12" style={{textAlignVertical: "center",textAlign: "center"}}>
                          
                          <video log={console.log('video_url',this.state.video_url)} key={this.state.video_url} id="playVid" controls style={{width: 'auto', maxHeight: 'calc(100vh - 150px)'}} ><source src={this.state.video_url} type="video/mp4" /></video>
                          {/* <video
                            ref={this.videoRef}
                            controls
                            style={{ width: 'auto', maxHeight: 'calc(100vh - 150px)' }}
                          /> */}
                          </div>
                        </div>
                      </Modal.Body>
                      </Modal>
                    }





                	</div>
                } />
            </Col>
          </Row>
        </Grid>
      </div>
  	);
  };
}
const AdListing = AdListingPage;
export default AdListing;
