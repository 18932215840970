import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import HeaderLinks from "./HeaderLinks.jsx";
import { basePathClient } from 'helper/basePathClient';
import defaultLogo from "assets/img/logo_white.png";
import {
  // Tooltip,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import dashboardRoutes from "routes/dashboard.jsx";
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import { history } from 'helper/history';
class Header extends Component {
  constructor(props) {
    super(props);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.getAlert = this.getAlert.bind(this);
    this.state = {
      sidebarExists: true,
      canvasMenuToggle: false
    };




    //console.log("HF.getRole()",HF.hasRole('Admin'));
    if (HF.hasRole('clm') == false) {
      localStorage.removeItem('user');
      // history.push('/');
      history.push('/CLM/CLM-login');
    }

  }
  getAlert() {
    alert('getAlert from Child');
  }

  mobileSidebarToggle(e) {
    if (this.state.sidebarExists === true) {
      this.setState({
        sidebarExists: true
      });
    }
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  }
  getBrand() {
    var name;
    dashboardRoutes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      } else {
        if (prop.redirect) {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        } else {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        }
      }
      return null;
    });
    return name;
  }
  render() {
    return (
      <Navbar className="CustomHeader" fluid>
        <Navbar.Header>
          <OverlayTrigger overlay={<Popover id="tooltip">Reload Current Module</Popover>}>
            <Navbar.Brand>
              <div className="logo" style={{ marginLeft: '10px' }}>
                <Link to={basePathClient + 'Admin'} className="simple-text logo-normal">
                  <div className="logo-img">
                    <img src={defaultLogo} alt="logo_image" height="40px" />
                  </div>
                </Link>
              </div>
            </Navbar.Brand>
          </OverlayTrigger>

          <Navbar.Toggle onClick={this.mobileSidebarToggle} />
        </Navbar.Header>
        <Navbar.Collapse>
          <HeaderLinks />
        </Navbar.Collapse>
      </Navbar>
    );
  }
};

export default Header;
